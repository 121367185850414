import React from 'react';
import styled from 'styled-components';
import logo from './imgs/logo.png';
import Widget from './components/widget/Widget';

const AppContainer = styled.div`
  text-align: center;
  font-size: 1.5rem;
`

const Logo = styled.img`
  height: 30vmin;
  pointer-events: none;
`

const Header = styled.header`
  background-color: #383838;
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
`

const SubHeader = styled.h3`
  margin: 0;
  text-align: left;
`

// const Link = styled.a`
//   color: #61dafb;
// `


function App() {
  return (
    <AppContainer>
      <Header>
        <Logo src={logo} className="App-logo" alt="logo" />
        <h2>
          Rich Works Studios
        </h2>
        <SubHeader>Minecraft Servers:</SubHeader>
        <Widget url="play.richworksstudios.com" />
        <Widget url="modded.richworksstudios.com" />
      </Header>
    </AppContainer>
  );
}

export default App;
