import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { MobileBreakpoint } from '../../utils/constants';

const Card = styled.div`
    min-width: 300px;
    max-width: calc(100vw - 2rem);
    width: calc(${MobileBreakpoint} + 40px);
    padding: 1rem;
    margin: 1rem;
    display: flex;
    flex-direction: row;

    @media screen and (max-width: ${MobileBreakpoint}px) {
        flex-direction: column;
        padding: .25rem;
        margin: .25rem;
        font-size: 1.25rem;
    }
    
    &:not(:last-child) {
        border-bottom: 1px solid #666;
    }
`;

const Info = styled.div`
    padding: 1rem;
    margin: 1rem;
    display: flex;
    flex-direction: column;
    text-align: left;

    @media screen and (max-width: ${MobileBreakpoint}px) {
        padding: .25rem;
        margin: .25rem;
    }
`;

const Icon = styled.div`
    padding: 1rem;
    margin: 1rem;
    justify-self: center;
    align-self: center;

    @media screen and (max-width: ${MobileBreakpoint}px) {
        padding: .25rem;
        margin: .25rem;
    }
`;

type WidgetProps = {
    url: string,
}

const Widget = ({url}: WidgetProps) => {
    const pingUrl = `https://api.minetools.eu/ping/${url}/25565`;
    const [data, setData] = useState<any>();

    useEffect(() => {
        fetch(pingUrl).then(response => {
            if (response.ok) {
                return response.json();
            }
            throw response;
        }).then(data => {
            setData(data);
        })
        .catch((e) => {
            console.log('Error retrieving server data', e);
        })
    }, [pingUrl]);

    return (
        <Card>
            <Icon>
                <img src={data?.favicon} id="favicon" alt="" />
            </Icon>
            <Info>
                {data ?
                    <>
                        <div>
                            <strong>{data?.description}</strong>{' '}<code>{url}</code>
                        </div>
                        <div><br /><strong>Players Online:</strong>{' '}{data?.players?.online}</div>
                    </>
                :
                    "Loading..."
                }
            </Info>
        </Card>
    );
}

export default Widget;